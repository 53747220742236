<template>
  <div>
    <v-card class="custom-table">
      <v-toolbar flat height="auto" class="custom-table-toolbar">
        <v-toolbar-items
          class="d-flex align-center"
          :class="{ 'flex-wrap': $vuetify.breakpoint.width < 1280 }"
          style="width: 100%"
        >
          <!--           <v-switch
            class="mr-5 mt-2 mb-3"
            v-if="enableSelect"
            v-model="tableTickboxToggle"
            inset
            dense
            hide-details
            label="啟用勾選"
          ></v-switch> -->
          <v-switch
            class="mr-5"
            v-if="enableWeekdayToggle"
            v-model="tableWeekdayToggle"
            inset
            dense
            hide-details
            label="顯示禮拜"
          ></v-switch>
          <v-text-field
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            v-if="enableSearch"
            v-model="searchText"
            :append-icon="mdiMagnify"
            :label="searchPlaceholder"
            single-line
            outlined
            dense
            hide-details
            clearable
            @click:clear="clearSearch()"
            @click:append="clickSearch()"
            @keydown.enter="clickSearch()"
            @input="inputSearch()"
          ></v-text-field>
          <v-menu
            style="width: 100%; max-width: 320px"
            v-if="enableDateRange"
            ref="dateRangeMenu"
            v-model="dateRangeMenu"
            :close-on-content-click="false"
            :return-value.sync="searchDateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-3 mt-2 mb-3"
                style="width: 100%; max-width: 320px"
                :value="displayCustomDateRange(searchDateRange)"
                :label="dateRangePlaceholder"
                :append-icon="mdiCalendarSearch"
                single-line
                outlined
                dense
                hide-details
                clearable
                readonly
                @click:clear="clearDateRange()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDateRange"
              range
              no-title
              scrollable
              :first-day-of-week="0"
              :day-format="$Formatter.dayFormat"
              locale="zh-hk"
            >
              <v-spacer></v-spacer>
              <v-btn text small color="error" @click="dateRangeMenu = false">
                <v-icon class="mr-1" small>{{ mdiClose }}</v-icon>
                取消
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                @click="
                  $refs.dateRangeMenu.save(searchDateRange)
                  selectDateRange()
                "
              >
                <v-icon class="mr-1" small>{{ mdiCheck }}</v-icon>
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-if="enableFilter"
            v-model="tableFilterModel"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            :items="tableFilterData"
            :menu-props="{ offsetY: true }"
            outlined
            hide-details
            dense
            @change="changeFilter()"
          ></v-select>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center">
          <v-btn v-if="enableClearAll" text small color="error" @click="clickClearAll()" :plain="!$vuetify.theme.dark">
            <v-icon class="mr-1" color="error">{{ mdiClose }}</v-icon>
            清除搜尋
          </v-btn>
          <v-btn v-if="enableRefresh" text small color="success" @click="clickRefresh()" :plain="!$vuetify.theme.dark">
            <v-icon class="mr-1" color="success">{{ mdiRefresh }}</v-icon>
            重新載入
          </v-btn>
          <v-btn
            v-if="enableExport"
            text
            small
            color="primary"
            :plain="!$vuetify.theme.dark"
            :href="exportUrlLocation"
            target="_blank"
          >
            <v-icon class="mr-1" color="primary">{{ mdiTableArrowRight }}</v-icon>
            匯出資料
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        class="custom-table"
        locale="zh-hk"
        :loading="isLoading"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="pageLimit"
        :page="page"
        :server-items-length="itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelect"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="disableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="disableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: '資料排序',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: enableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: '每頁顯示項目:',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: '全部',
          pageText:
            '顯示第' +
            (tableData.length > 1
              ? (page - 1) * pageLimit + 1 + '-' + Math.min(page * pageLimit, totalNumberCount)
              : (page - 1) * pageLimit + 1) +
            '個項目',
        }"
        loading-text="資料處理中"
        no-data-text="没有資料"
        no-results-text="没有資料"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
        @input="emitSelectItems"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="enableFirstLastPage"
            :items-per-page-text="''"
            :page-text="
              '共' +
              (totalNumberCount % pageLimit == 0
                ? totalNumberCount / pageLimit
                : parseInt(totalNumberCount / pageLimit) + 1) +
              '頁 / ' +
              totalNumberCount +
              '個項目'
            "
            @update:options="updateOptions"
          >
          </v-data-footer>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ item.create_date.split('T')[0] + ' ' + item.create_date.split('T')[1].substring(0, 8) }}
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          {{
            item.phone !== undefined && item.phone.length === 8
              ? item.phone.substring(0, 4) + ' ' + item.phone.substring(4)
              : item.phone
          }}
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <v-img
            class="my-2"
            :src="
              $validate.DataValid(item.photo)
                ? `${$mediaPath}${item.photo}`
                : require('@/assets/images/default_image.svg')
            "
            width="150"
            contain
          ></v-img>
        </template>

        <template v-slot:[`item.cargo_image`]="{ item }">
          <!--           <v-img
            class="my-2"
            :src="
              $validate.DataValid(item.photo) ? `/media/${item.photo}` : require('@/assets/images/default_image.svg')
            "
            width="150"
            contain
            style="margin: 0 auto"
          ></v-img> -->
          <span>{{ $validate.DataValid(item.photo) ? item.photo.length : 0 }}張</span>
        </template>

        <template v-slot:[`item.bar_code_image`]="{ item }">
          <template v-if="$validate.DataValid(item.bar_code_image)">
            <v-img
              class="my-2"
              :src="
                $validate.DataValid(item.bar_code_image)
                  ? `${$mediaPath}${item.bar_code_image}`
                  : require('@/assets/images/default_image.svg')
              "
              width="150"
              contain
              style="margin: 0 auto"
            ></v-img>
            <div class="barcode">{{ item.bar_code }}</div>
          </template>
          <VueBarcode v-else :value="item.bar_code" tag="svg"></VueBarcode>
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <span>{{ item.type | formatContainerType }}</span>
        </template>

        <template v-slot:[`item.cargo_status`]="{ item }">
          <span>{{ item.cargo_status | formatCargoStatus }}</span>
        </template>

        <template v-slot:[`item.actionDetails`]="{ item }">
          <v-btn text color="secondary" small @click="emitDetailsClickEvent(item.id)">
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionView`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            :to="{
              name: actionViewRouteLink,
              params: { id: item.id, searchCargoID: $validate.DataValid(item.cargoID) ? item.cargoID : undefined },
            }"
          >
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewWithParams`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            :to="{
              name: actionViewRouteLink,
              params: { type: item.loan_type },
            }"
          >
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewEditDelete`]="{ index }">
          <v-btn text color="primary" small :loading="submitLoading" @click="emitTableActionEvent('edit', index)">
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            更改
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
            刪除
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewViewDelete`]="{ index }">
          <v-btn
            text
            color="primary"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('view', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            查看
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            刪除
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewDelete`]="{ index }">
          <v-btn
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            刪除
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewEditDeletePDF`]="{ index }">
          <!--           <v-btn
            v-if="item.cargo_status === 'picked'"
            text
            color="accent"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('pdf', index)"
            :disabled="disablePagination"
          >
            下載PDF
          </v-btn> -->

          <v-btn
            class="mr-1"
            text
            color="primary"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('edit', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            更改
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            刪除
          </v-btn>
        </template>

        <template v-slot:[`item.ExportContainerTableBtn`]="{ item, index }">
          <v-btn
            text
            color="info"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('open', index)"
            :disabled="disablePagination"
          >
            查看貨箱資料
          </v-btn>
          <v-btn
            :class="{ 'mr-1': $validate.DataValid(item.id) }"
            text
            color="primary"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('edit', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            更改
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            刪除
          </v-btn>
        </template>

        <template v-slot:[`item.PickUpContainerTableBtn`]="{ item, index }">
          <v-btn
            v-if="$validate.DataValid(item.id)"
            text
            color="info"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('open', index)"
            :disabled="disablePagination"
          >
            查看貨箱資料
          </v-btn>
          <v-btn
            :class="{ 'mr-1': $validate.DataValid(item.id) }"
            text
            color="primary"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('edit', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            更改
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', index)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            刪除
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
import {
  mdiEyeOutline,
  mdiTrashCanOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiCancel,
  mdiCheck,
  mdiClose,
  mdiDeleteForever,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiNotePlusOutline,
  mdiTableArrowRight,
  mdiEmail,
  mdiPhone,
  mdiCalendarClock,
  mdiCardAccountDetails,
  mdiPencil,
} from '@mdi/js'
import VueBarcode from '@chenfengyuan/vue-barcode'

export default {
  // ------ page properties ------
  name: 'Datatable',
  components: { VueBarcode },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableFilterData: {
      type: Array,
      default: () => [{ text: '全部', value: 'all' }],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    enableSelect: {
      type: Boolean,
      default: false,
    },
    enableWeekdayToggle: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: true,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableFilter: {
      type: Boolean,
      default: false,
    },
    enableDateRange: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableFirstLastPage: {
      type: Boolean,
      default: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disableFiltering: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },

    pageLimitOptions: {
      type: Array,
      default: () => [5, 10, 20, 50],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '搜尋',
    },
    dateRangePlaceholder: {
      type: String,
      default: '按日期範圍搜尋',
    },
    confirmDeleteDes: {
      type: String,
      default: '此動作將無法還原',
    },
    exportUrl: {
      type: String,
      default: '/api/cms-export',
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
  },

  // ------ page variables ------
  data: () => ({
    tableInit: false,
    tableWeekdayToggle: true,
    tableTickboxToggle: false,
    tableFilterModel: 'all',
    options: {},
    dialogConfirmDelete: {
      show: false,
      loading: false,
      data_type: '',
      data_id: -1,
    },
    searchText: '',
    searchDateRange: [],
    dateRangeMenu: false,

    dataDeleted: false,
    dataFailedToDelete: false,

    // icon
    mdiEyeOutline,
    mdiTrashCanOutline,
    mdiAlertOutline,
    mdiCancel,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiRefresh,
    mdiMagnify,
    mdiCalendarSearch,
    mdiNotePlusOutline,
    mdiCheckCircle,
    mdiAlert,
    mdiTableArrowRight,
    mdiEmail,
    mdiPhone,
    mdiCalendarClock,
    mdiCardAccountDetails,
    mdiPencil,
  }),

  // ------ mutation observer ------
  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
  },

  // ------ computed values ------
  computed: {
    exportUrlLocation() {
      let url = this.exportUrl + '?sort='
      if (this.options && this.options.sortBy && this.options.sortBy.length) {
        url += this.options.sortBy[0]
      } else {
        url += 'create_date'
      }
      if (this.options && this.options.sortDesc && this.options.sortDesc.length) {
        url += '&order=' + ((this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true) ? 'desc' : 'asc')
      } else {
        url += '&order=desc'
      }
      if (this.searchText !== '') {
        url += '&search=' + encodeURIComponent(this.searchText)
      }

      if (this.searchDateRange.length >= 2) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[1]
      } else if (this.searchDateRange.length === 1) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[0]
      }

      return url
    },
    totalNumberCount() {
      if (this.itemTotal === -1) {
        return this.tableData.length
      }
      return this.itemTotal
    },
  },

  // ------ life cycle events ------
  created() {},

  // ------ page functions ------
  methods: {
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.$Formatter.displayWeekday(date[1])}) ~ ${
            date[0]
          } (${this.$Formatter.displayWeekday(date[0])})`
        } else {
          return `${date[0]} (${this.$Formatter.displayWeekday(date[0])}) ~ ${
            date[1]
          } (${this.$Formatter.displayWeekday(date[1])})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.$Formatter.displayWeekday(date[0])})`
      }

      return ''
    },
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    clickSearch() {
      this.$emit('search-clicked', this.searchText)
    },
    inputSearch() {
      this.$emit('search-input', this.searchText)
    },
    clearSearch() {
      this.$emit('search-cleared')
    },
    selectDateRange() {
      if (this.searchDateRange.length === 2) {
        if (new Date(this.searchDateRange[0]).getTime() > new Date(this.searchDateRange[1]).getTime()) {
          this.$emit('daterange-selected', [this.searchDateRange[1], this.searchDateRange[0]])
        } else {
          this.$emit('daterange-selected', this.searchDateRange)
        }
      } else {
        this.$emit('daterange-selected', this.searchDateRange)
      }
    },
    clearDateRange() {
      this.searchDateRange = []
      this.$emit('daterange-cleared')
    },
    clickClearAll() {
      this.searchText = ''
      this.searchDateRange = []
      this.tableFilterModel = 'all'
      this.$emit('all-search-cleared')
    },
    changeFilter() {
      this.$emit('filter-changed', this.tableFilterModel)
    },
    emitDetailsClickEvent(dataId) {
      this.$emit('details-clicked', dataId)
    },
    emitTableActionEvent(type, id) {
      this.$emit('tableAction', type, id)
    },
    emitSelectItems(items) {
      this.$emit('selectItems', items)
    },
  },
}
</script>

<style>
.v-data-table.custom-table .v-data-table-header-mobile .v-text-field .v-select__selections {
  margin-top: 0.5rem;
}
.custom-table-toolbar .v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.875rem;
}

.custom-table-toolbar .v-input input,
.custom-table-toolbar .v-input label {
  font-size: 0.825rem;
}

.v-data-table.custom-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table.custom-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table.custom-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 0.825rem;
  font-weight: 500;
}

.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  padding-top: 1rem;
}
.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row:last-of-type {
  padding-bottom: 1rem;
}
.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 2.2rem;
}

.hide-page-select .v-data-footer__select {
  visibility: none;
  opacity: 0;
  pointer-events: none;
}

.v-dialog.table-dialog {
  margin: 0 auto;
}

.address-width {
  max-width: 250px;
}

.barcode {
  color: #4b4b4b;
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 1600px) {
  .custom-table-toolbar .v-toolbar__content {
    flex-wrap: wrap;
  }
  .custom-table-toolbar .v-toolbar__content .spacer {
    display: none;
  }
}
</style>
